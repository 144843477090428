import React, { useState, useEffect, useRef } from 'react'
import { FiUpload } from 'react-icons/fi'
import { Button } from "react-bootstrap";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify'
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import ReCAPTCHA from "react-google-recaptcha";

const PersonalInfo = ({formData, setFormData}) => {
    const [isVerified, setVerified] = useState(false)
    const [privacy, setPrivacy] = useState(false)
    const [plik, setPlik] = useState(null);
    const {t} = useTranslation();
    const [reCaptcha, setReCaptcha] = useState();
    const [bool, setBool] = useState(true);
    const firstCheckbox = (e) => {
        if(e.target.checked){
            setFormData({...formData, set: 'Tak'})
        }else{
            setFormData({...formData, set: 'Nie'})
        }
    }

    const secondCheckbox = (e) => {
        if(e.target.checked){
            setFormData({...formData, permission: 'Tak'})
        }else{
            setFormData({...formData, permission: 'Nie'})
        }
    }
    
    const thirdCheckbox = (e) => {
        if(e.target.checked){
            setFormData({...formData, land_available: 'Tak'})
        }else{
            setFormData({...formData, land_available: 'Nie'})
        }
    }

    const privacyPolicy = (e) => {
        if(e.target.checked){
            setFormData({...formData, privacy_policy: 'Tak'})
            setPrivacy(true)
        }else{
            setFormData({...formData, privacy_policy: 'Nie'})
            setPrivacy(false)
        }
    }

    function onChange(value) {
        setVerified(true)
    }

    useEffect(() => {
        const button = document.querySelector('#sendButton')
        if(button != null){
            if(isVerified && privacy){
                button.classList.remove('noactive-step-btn')
                button.classList.add('step-btn')
            }else{
                button.classList.remove('step-btn')
                button.classList.add('noactive-step-btn')
            }
        }
    }, [isVerified, privacy])

    const afterSuccess = () => {
        window.location.reload()
    }

    const SendingEmail = () => {
        if(
          formData.location == "" || 
          formData.height ==  "" ||
          formData.eaves_construction == '' ||
          formData.hall_area == '' ||
          formData.hallenkran == '' ||
          formData.address ==''||
          formData.language == '' ||
          formData.country == '' ||
          formData.sex == "" ||
          formData.localization ==''||
          formData.start == '') {  
       } else {  
              let templateParams = {
                my_html:`<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
                    <meta name="viewport" content="width=device-width">
                        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css" integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr" crossorigin="anonymous">
                    <style type="text/css">body, html {
                      margin: 0px;
                      padding: 0px;
                      -webkit-font-smoothing: antialiased;
                      text-size-adjust: none;
                      width: 100% !important;
                    }
                      table td, table {
                      }
                      #outlook a {
                        padding: 0px;
                      }
                      .ExternalClass, .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td, .ExternalClass div {
                        line-height: 100%;
                      }
                      .ExternalClass {
                        width: 100%;
                      }
                      @media only screen and (max-width: 480px) {
                        table, table tr td, table td {
                          width: 100% ;
                        }
                        table tr td table.edsocialfollowcontainer  {
                          width: auto;
                        }
                        img {
                          width: inherit;
                        }
                        .layer_2 {
                          max-width: 100% !important;
                        }
                        .edsocialfollowcontainer table {
                          max-width: 25% !important;
                        }
                        .edsocialfollowcontainer table td {
                          padding: 10px !important;
                        }
                        .edsocialfollowcontainer table {
                          max-width: 25% !important;
                        }
                        .edsocialfollowcontainer table td {
                          padding: 10px !important;
                        }
                      }
        
                      
        /* configutator */
        
        #content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background: white;
            padding: 1em;
            width: 100em;
            z-index: 999;
        }
        
        #content span{
            color: black;
        }
        
        #content h1{
            align-self: flex-start !important;
        }
        
        #map * {
            overflow:visible;
        }
        
        /* configutator */
        
                    </style>
                    <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i &subset=cyrillic,latin-ext" data-name="open_sans" rel="stylesheet" type="text/css">
                    <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/spectrum/1.8.0/spectrum.min.css">
                  </head><body style="padding:0; margin: 0;background: #e4e6ec">
        
                    
        
                    <table style="height: 100%; width: 100%; background-color: #e4e6ec;" align="center">
                      <tbody>
                        <tr>
                          <td valign="top" id="dbody" data-version="2.31" style="width: 100%; height: 100%; padding-top: 50px; padding-bottom: 50px; background-color: #e4e6ec;">
                            <!--[if (gte mso 9)|(IE)]><table align="center" style="max-width:600px" width="600" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top"><![endif]-->
                            
        
                            
        
                           <table class="layer_1" align="center" border="0" cellpadding="0" cellspacing="0" style="max-width: 600px; box-sizing: border-box; width: 100%; margin: 0px auto;">
                              <tbody>
                                
                                <tr>
                                  <td class="drow" valign="top" align="center" style="background-color: #e4e6ec; box-sizing: border-box; font-size: 0px; text-align: center;">
                                    <!--[if (gte mso 9)|(IE)]><table width="100%" align="center" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top"><![endif]-->
                                    <div class="layer_2" style="max-width: 596px; display: inline-block; vertical-align: top; width: 100%;">
                                      <table border="0" cellspacing="0" cellpadding="0" class="edcontent" style="border-collapse: collapse;width:100%">
                                        <tbody>
                                          <tr>
                                            <td valign="top" class="emptycell" style="padding: 10px;">
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
                                  </td>
                                </tr>
                                <tr>
                                  <td class="drow" valign="top" align="center" style="background-color: #ffffff; box-sizing: border-box; font-size: 0px; text-align: center;">
                                    <!--[if (gte mso 9)|(IE)]><table width="100%" align="center" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top"><![endif]-->
                                    <div class="layer_2" style="max-width: 596px; display: inline-block; vertical-align: top; width: 100%;">
                                      <table border="0" cellspacing="0" cellpadding="0" class="edcontent" style="border-collapse: collapse;width:100%">
                                        <tbody>
                                          <tr>
                                            <td valign="top" class="emptycell" style="padding: 10px;">
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
                                  </td>
                                </tr>
        
                                
        
                                <tr>
                                  <td class="drow" valign="top" align="center" style="background-color: #ffffff; box-sizing: border-box; font-size: 0px; text-align: center;">
                                    <!--[if (gte mso 9)|(IE)]><table width="100%" align="center" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top"><![endif]-->
                                    <div class="layer_2" style="max-width: 596px; display: inline-block; vertical-align: top; width: 100%;">
                                      <table border="0" cellspacing="0" class="edcontent" style="border-collapse: collapse;width:100%">
                                        <tbody>
                                          <tr>
                                            <td valign="top" class="edtext" style="padding: 20px; text-align: left; color: #5f5f5f; font-size: 12px; font-family: &quot;Open Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; word-break: break-word; direction: ltr; box-sizing: border-box;">
                                              <p class="style1 text-center" style="text-align: center; margin: 0px; padding: 0px; color: #000000; font-size: 32px; font-family: &quot;Open Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif;">Masz nowe zapytanie!</p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
                                  </td>
                                </tr>
                                <tr><td class="drow" valign="top" align="center" style="background-color: #ffffff; box-sizing: border-box; font-size: 0px; text-align: center;"><!--[if (gte mso 9)|(IE)]><table width="100%" align="center" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top"><![endif]--><div class="layer_2" style="max-width: 600px; display: inline-block; vertical-align: top; width: 100%;"><table border="0" cellspacing="0" class="edcontent" style="border-collapse: collapse;width:100%"><tbody><tr><td valign="top" class="edtext" style="padding: 20px; text-align: left; color: #5f5f5f; font-size: 12px; font-family: &quot;Open Sans&quot;, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif; word-break: break-word; direction: ltr; box-sizing: border-box;"><p style="margin: 0px; padding: 0px;"><strong>Imię i nazwisko pytającego: ${formData.name}</strong></p><p style="margin: 0px; padding: 0px;"><strong>Adres e-mail: ${formData.email}</strong></p><p style="margin: 0px; padding: 0px;"><strong><br></strong></p>
        
            
                            <h1>Hala</h1>
        
                            <h2>Podstawowe informacje </h2>
        
                            <span>Lokalizacja hali: ${formData.location}</span><br>
                            <span>Szerokosc(cm): ${formData.width}</span><br>
                            <span>Długość(cm): ${formData.length}</span><br>
                            <span>Wysokość okapu(cm): ${formData.eaves_construction}</span><br>
                            <span>Wysokosc kalenicy(cm): ${formData.height}</span><br>
                            <span>Nachylenie dachu w °: ${formData.roof_slope}</span><br>
                            <span>Powierzchnia hali: ${formData.hall_area}</span><br>
                            <span>Przewidywany początek budowy: ${formData.start}</span><br>
    
                            <h1>Właściciel</h1>
        
                            <h2>Imie i nazwisko : ${formData.name}</h2>
                            <h2>Firma: ${formData.firm}</h2>
                            <h2>Adres: ${formData.address}</h2>
                            <h2>Lokalizacja: ${formData.localization}</h2>
                            <h2>Język: ${formData.language}</h2>
                            <h2>E-mail: ${formData.email}</h2>
                            <h2>Funkcja: ${formData.function}</h2>
                            <h2>Kraj: ${formData.country}</h2>
                            <h2>Numer telefonu: ${formData.phone}</h2>
                            <h2>Dostawa jako zestaw(bez montażu): ${formData.set}</h2>
                            <h2>Możliwość uzyskania pozwolenia na budowę: ${formData.permission}</h2>
                            <h2>Dostępna ziemia: ${formData.land_available}</h2>
                            <h2>Plik PDF: ${formData.file}</h2>
                            <h2>Uwagi wlasciciela: ${formData.message}</h2>
        
            
        </td></tr></tbody></table></div><!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]--></td></tr> 
                                
                                <tr>
                                  <td class="drow" valign="top" align="center" style="background-color: #f4f4f3; box-sizing: border-box; font-size: 0px; text-align: center;">
                                    <!--[if (gte mso 9)|(IE)]><table width="100%" align="center" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top"><![endif]-->
                                    <div class="layer_2" style="max-width: 596px; display: inline-block; vertical-align: top; width: 100%;">
                                      
        
        
                                    
                            
        
                                    </div>
                                    <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
                                  </td>
                                </tr>
        
                                <tr>
                                  <td class="drow" valign="top" align="center" style="background-color: #ffffff; box-sizing: border-box; font-size: 0px; text-align: center;">
                                    <!--[if (gte mso 9)|(IE)]><table width="100%" align="center" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top"><![endif]-->
                                    <div class="layer_2" style="max-width: 596px; display: inline-block; vertical-align: top; width: 100%;">
                                      <table border="0" cellspacing="0" cellpadding="0" class="edcontent" style="border-collapse: collapse;width:100%">
                                        <tbody>
                                          <tr>
                                            <td valign="top" class="emptycell" style="padding: 10px;">
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
                                  </td>
                                </tr>
                                <tr>
                                  
                                  <td class="drow" valign="top" align="center" style="background-color: #ffffff; box-sizing: border-box; font-size: 0px; text-align: center;">
                                    <!--[if (gte mso 9)|(IE)]><table width="100%" align="center" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top"><![endif]-->
                                    
                                    
                                      <div class="layer_2" style="max-width: 296px; display: inline-block; vertical-align: top; width: 100%;">
                                      <table border="0" cellspacing="0" class="edcontent" style="border-collapse: collapse;width:100%">
                                     
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--[if (gte mso 9)|(IE)]></td><td valign="top"><![endif]-->
                                    <div class="layer_2" style="max-width: 296px; display: inline-block; vertical-align: top; width: 100%;">
                                      <table border="0" cellspacing="0" class="edcontent" style="border-collapse: collapse;width:100%">

                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
                                  </td>
                                </tr>
                                <tr>
                                  <td class="drow" valign="top" align="center" style="background-color: #ffffff; box-sizing: border-box; font-size: 0px; text-align: center;">
                                    <!--[if (gte mso 9)|(IE)]><table width="100%" align="center" cellpadding="0" cellspacing="0" border="0"><tr><td valign="top"><![endif]-->
                                    <div class="layer_2" style="max-width: 596px; display: inline-block; vertical-align: top; width: 100%;">
                                      <table border="0" cellspacing="0" cellpadding="0" class="edcontent" style="border-collapse: collapse;width:100%">
                                        <tbody>
                                          <tr>
                                            <td valign="top" class="emptycell" style="padding: 10px;">
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
                                  </td>
                                </tr>
                               
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <!--[if (gte mso 9)|(IE)]></td></tr></table><![endif]-->
                          </td>
                        </tr>
                      </tbody>
                    </table>`,
    
                    email: formData.your_mail,
                    
                }
                const path = window.location.pathname.slice(1, window.location.pathname.length);
                
                // if(path === ''){
                //   emailjs.send('service_3zq4d2g', 'template_tiksw1w', templateParams, 'ZB0bPoKirk_oa4_P7')
                //   .then(res => {
                //       toast.success(t('Dziękujemy za kontakt!'))
                //       setTimeout(afterSuccess ,6000)
                //   })
                //   .catch(err => console.log(err))
                // }
                
                if(i18next.language === 'pl'){
                  emailjs.send('service_3zq4d2g', 'template_tiksw1w', templateParams, 'ZB0bPoKirk_oa4_P7')
                  .then(res => {
                      toast.success(t('Dziękujemy za kontakt!'))
                      setTimeout(afterSuccess ,6000)
                  })
                  .catch(err => {console.log(err); alert('Błąd wysyłania')})
                }

                if(i18next.language === 'en'){
                  emailjs.send('service_eqkonxq', 'template_rnaih1a', templateParams, 'X5jIZ34N-_80fsom3')
                  .then(res => {
                      toast.success(t('Dziękujemy za kontakt!'))
                      setTimeout(afterSuccess ,6000)
                  })
                  .catch(err => console.log(err))
                }

                if(i18next.language === 'de'){
                  emailjs.send('service_1h6b5qq', 'template_pltso3d', templateParams, 'wsD33yFevKP3s4rn4')
                  .then(res => {
                      toast.success(t('Dziękujemy za kontakt!'))
                      setTimeout(afterSuccess ,6000)
                  })
                  .catch(err => console.log(err))
                }

                if(i18next.language === 'cs'){
                  emailjs.send('service_zx9pqk4', 'template_xrgny2l', templateParams, 'K0t7xDkMIuuE7XauS')
                  .then(res => {
                      toast.success(t('Dziękujemy za kontakt!'))
                      setTimeout(afterSuccess ,6000)
                  })
                  .catch(err => console.log(err))
                }

                if(i18next.language === 'fr'){
                  emailjs.send('service_kkgea9k', 'template_3a391ai', templateParams, 'Yn8r7T5jCkxtNuSEZ')
                  .then(res => {
                      toast.success(t('Dziękujemy za kontakt!'))
                      setTimeout(afterSuccess ,6000)
                  })
                  .catch(err => console.log(err))
                }

                if(i18next.language === 'fi'){
                  emailjs.send('service_6b74tbb', 'template_ueq1juo', templateParams, 'NPzRzJlTWR7h1OlYp')
                  .then(res => {
                      toast.success(t('Dziękujemy za kontakt!'))
                      setTimeout(afterSuccess ,6000)
                  })
                  .catch(err => console.log(err))
                }

                if(i18next.language === 'es'){
                  emailjs.send('service_0nvtdpg', 'template_sb4pxkr', templateParams, 'Z62d2WgHeDxVj7v9r')
                  .then(res => {
                      toast.success(t('Dziękujemy za kontakt!'))
                      setTimeout(afterSuccess, 6000)
                  })
                  .catch(err => console.log(err))
                }

                if(i18next.language === 'be'){
                  emailjs.send('service_frb7d5e', 'template_dnuathi', templateParams, 'Hu9jfS_6qV3kALpOK')
                  .then(res => {
                      toast.success(t('Dziękujemy za kontakt!'))
                      setTimeout(afterSuccess, 6000)
                  })
                  .catch(err => console.log(err))
                }
                
                if(i18next.language === 'uks'){
                  emailjs.send('service_b3nnnvg', 'template_d7dac7q', templateParams, '6FIxXsZkzHD3-kR8n')
                  .then(res => {
                      toast.success(t('Dziękujemy za kontakt!'))
                      setTimeout(afterSuccess, 6000)
                  })
                  .catch(err => console.log(err))
                }

                if(i18next.language === 'aut'){
                  emailjs.send('service_8whsf81', 'template_en78hfw', templateParams, 'sTWwR-jdgYAK5x37f')
                  .then(res => {
                      toast.success(t('Dziękujemy za kontakt!'))
                      setTimeout(afterSuccess, 6000)
                  })
                  .catch(err => console.log(err))
                }
                if(i18next.language === 'sk'){
                  emailjs.send('service_lo0zfpw', 'template_pjaoqtk', templateParams, '3qqIJXixJsnJgfwac')
                  .then(res => {
                      toast.success(t('Dziękujemy za kontakt!'))
                      setTimeout(afterSuccess, 6000)
                  })
                  .catch(err => console.log(err))
                }
        }
      }  

      const takeFile = (acceptFiles) => {
        setPlik(acceptFiles[0]);
      }

    useEffect(() => {
      const select = document.querySelector('#lang-select');
      if(select !== undefined && select !== null) select.style.display = 'none';
    }, [])

    const { i18n } = useTranslation();

    return (
        <>
            <div className='additional-information'>
            <label><span>{t('Imię i nazwisko*')}</span><input type='text' value={formData.name} onChange={(e) =>  {setFormData({...formData, name: e.target.value})} }/></label>
            <label><span>{t('Firma (opcjonalnie)*')}</span><input type='text' value={formData.firm} onChange={(e) => {setFormData({...formData, firm: e.target.value})}}/></label>
            <label><span>{t('Adres firmy*')}</span><input type='text' value={formData.address} onChange={(e) =>  {setFormData({...formData, address: e.target.value})} }/></label>
            <label><span>{t('Lokalizacja inwestycji*')}</span><input type='text' value={formData.localization} onChange={(e) =>  {setFormData({...formData, localization: e.target.value})} }/></label>
            <label><span>{t('Język*')}</span><select value={formData.language} onChange={(e) =>  {setFormData({...formData, language: e.target.value})} }>
            <option>{t('Polska')}</option>
            <option>{t('Anglia')}</option>
            <option>{t('Niemcy')}</option>
            <option>{t('Czechy')}</option>
            <option>{t('Francja')}</option>
            <option>{t('Finlandia')}</option>
            <option>{t('Hiszpania')}</option>
            <option>{t('Belgia')}</option>
            <option>{t('Szkocja')}</option>
            <option>{t('Słowacja')}</option>
            </select></label>
            <label><span>{t('E-mail*')}</span><input type='email' value={formData.email} onChange={(e) =>  {setFormData({...formData, email: e.target.value})} }/></label>
            <label><span>{t('Przeznaczenie budynku*')}</span><input type='text' value={formData.function} onChange={(e) =>  {setFormData({...formData, function: e.target.value})} }/></label>
            <label><span>{t('Kraj*')}</span><input type='text' value={formData.country} onChange={(e) =>  {setFormData({...formData, country: e.target.value})} }/></label>
            <label><span>{t('Numer telefonu*')}</span><input type='tel' value={formData.phone} onChange={(e) =>  {setFormData({...formData, phone: e.target.value})} }/></label>
            <label><span>{t('Przewidywany początek budowy*')}</span><input type='date' lang="en-GB" value={formData.start} onChange={(e) =>  {setFormData({...formData, start: e.target.value})} }/></label>
            <div className='checkbox'>    
                <label><input className='chx' type='checkbox' value={formData.permission} onChange={(e) => secondCheckbox(e) }/><span>{t('Możliwość uzyskania pozwolenia na budowę')}</span></label>     
                <label><input className='chx' type='checkbox' value={formData.land_available} onChange={(e) => thirdCheckbox(e) }/><span>{t('Dostępna ziemia')}</span></label>
            </div>
            <label><span>{t('Komentarz-Życzenia-Pytania')}</span><textarea cols='100' rows='10' style={{width: '100%'}} value={formData.message} onChange={(e) =>{setFormData({...formData, message: e.target.value})}}></textarea></label>
            <div className='privacyPolicy'>
                <label className='SEcheckbox'><input type='checkbox' value={formData.privacy_policy} onChange={(e) => privacyPolicy(e) }/><span id="politics_position">{t('Tak, zgadzam się z polityką prywatności.')}</span></label>
            </div>
            </div>
            <ReCAPTCHA
              sitekey="6LfcCr0pAAAAAKGASRzWxhMvKkEGyZXwEUZchdMq"
              onChange={onChange}
              hl={i18n.language}
            />

            <Button className='step-btn' id='sendButton' onClick={SendingEmail} disabled={isVerified && privacy ? false : true}><span>{t('Wyślij')}</span>
            <svg className='arrow-' viewBox="0 0 24 24">
                <path
                    d="M22.6 11L15 3.4c-.5-.5-1.4-.5-1.9 0s-.5 1.4 0 1.9l5.3 5.3H2.3c-.7.1-1.3.7-1.3 1.4s.6 1.3 1.3 1.3h16.1l-5.3 5.3c-.5.5-.5 1.4 0 1.9s1.4.5 1.9 0l7.6-7.6c.3-.3.4-.6.4-1 0-.3-.1-.6-.4-.9z"
                    className="st0"
                ></path>
            </svg>
            </Button>
        </>
    )
}

export default PersonalInfo