import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ReactiveButton from 'reactive-button';
import { Stepper, Step, StepLabel } from '@mui/material';
import data from '../data'
import { useTranslation } from 'react-i18next';

/* components */

import Map from './partials/Map'
import PersonalInfo from './partials/PersonalInfo'
import Configurate from './partials/Configurate'
import SaveM from './partials/SaveM'

/* components */

const WidgetMain = () => {
  const {t} = useTranslation();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState(data.data);
  const [ButtonNextName, setButtonNextName] = useState('Przejdź dalej');
  const FormTitles = [
    t('Witaj kliencie!'),
    t('Wymiary'),
    t('Szczegóły projektu'),
    t('Szczegóły projektu')
  ]
  

  const DynamicalPageSet = () => {

    if(step == -1) {
      setStep(0)
    }
    

    if(step == 0) {
      return <Map formData={formData} setFormData={setFormData}/>
    } else if(step == 1) {
      return <Configurate formData={formData} setFormData={setFormData}/>
    } else if(step == 2) {
      return <PersonalInfo formData={formData} setFormData={setFormData}/>
    } else if(step == 3) {
      return <PersonalInfo formData={formData} setFormData={setFormData}/>
    } else if(step == 4) {
      return <>  <div className='widget-form' style={{ justifyContent: 'center', alignSelf: 'center' }}><h3>{t('Dziękujemy za kontakt!')}</h3></div> </>
    }

  }
  return (
    
    <>
    <div className='step-widget'>

<div className='container-for-stepper'>
<Stepper activeStep={step} alternativeLabel>
    <Step key={1} onClick={() => setStep(0)}>
      <StepLabel>
      <svg className='icon-bar' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.1 255.1">
  <path d="M124.3,241.6c-5.2,0-10.7-3.1-23-18.7c-8-10.1-16.9-23.3-25.3-37.1c-12.8-21.1-34.3-60.5-34.3-86.5
						c0-45.6,37.1-82.6,82.6-82.6c45.6,0,82.6,37.1,82.6,82.6c0,26-21.5,65.3-34.3,86.5c-8.4,13.8-17.4,27-25.3,37.1
						C135,238.5,129.5,241.6,124.3,241.6z M127.3,223.3L127.3,223.3L127.3,223.3z M121.2,223.3L121.2,223.3L121.2,223.3z M124.3,35.7
						c-35.1,0-63.6,28.5-63.6,63.6c0,14.6,10.8,41.6,29,72.4c14.6,24.7,28.1,42.3,34.6,48.9c6.5-6.7,20-24.2,34.6-48.9
						c18.2-30.7,29-57.8,29-72.4C187.9,64.2,159.4,35.7,124.3,35.7z" />
  <path d="M124.3,124.5c-18.9,0-34.3-15.4-34.3-34.3s15.4-34.3,34.3-34.3c18.9,0,34.3,15.4,34.3,34.3S143.2,124.5,124.3,124.5z
						M124.3,74.9c-8.4,0-15.3,6.9-15.3,15.3s6.9,15.3,15.3,15.3c8.4,0,15.3-6.9,15.3-15.3S132.7,74.9,124.3,74.9z" />
</svg>
      </StepLabel>
    </Step>
    {/* <Step key={2} onClick={() => setStep(1)}>
      <StepLabel>
     
      <svg className='icon-bar' style={{ height: '25px' }}
      xmlns="http://www.w3.org/2000/svg"
      fill="#000"
      version="1.1"
      viewBox="0 0 337.559 337.559"
      xmlSpace="preserve"
    >
      <path d="M337.559 67.704v-28.33c0-17.506-14.242-31.748-31.748-31.748h-54.572a32.297 32.297 0 00-16.922-4.765H32.5C14.58 2.86 0 17.44 0 35.36v266.838c0 17.921 14.58 32.5 32.5 32.5h201.816a32.3 32.3 0 0016.925-4.767h54.569c17.506 0 31.748-14.242 31.748-31.748v-28.33c0-9.715-4.391-18.42-11.287-24.248 6.896-5.828 11.287-14.533 11.287-24.248v-28.331c0-9.715-4.391-18.42-11.287-24.248 6.896-5.828 11.287-14.533 11.287-24.248V116.2c0-9.715-4.391-18.42-11.287-24.248 6.897-5.829 11.288-14.534 11.288-24.248zm-85.743 234.494c0 9.649-7.851 17.5-17.5 17.5H32.5c-9.649 0-17.5-7.851-17.5-17.5V35.36c0-9.649 7.851-17.5 17.5-17.5h201.816c9.649 0 17.5 7.851 17.5 17.5v266.838zm70.743-4.014c0 9.235-7.513 16.748-16.748 16.748h-41.595a32.306 32.306 0 002.601-12.733v-49.093h38.994c9.235 0 16.748 7.513 16.748 16.748v28.33zm0-76.827c0 9.235-7.513 16.748-16.748 16.748h-38.994v-61.827h38.994c9.235 0 16.748 7.513 16.748 16.748v28.331zm0-76.827c0 9.235-7.513 16.748-16.748 16.748h-38.994V99.451h38.994c9.235 0 16.748 7.513 16.748 16.748v28.331zm0-76.826c0 9.235-7.513 16.748-16.748 16.748h-38.994V35.36c0-4.518-.929-8.822-2.602-12.735h41.596c9.235 0 16.748 7.513 16.748 16.748v28.331z"></path>
      <path d="M40.413 230.024H226.404V245.024H40.413z"></path>
      <path d="M66.891 206.201h133.035a7.499 7.499 0 007.336-9.059c-3.595-16.907-13.071-32.176-26.474-43.02 8.782-10.818 13.689-24.438 13.689-38.522 0-33.674-27.396-61.07-61.07-61.07s-61.07 27.396-61.07 61.07c0 14.084 4.908 27.704 13.689 38.522-13.402 10.844-22.878 26.112-26.472 43.02a7.497 7.497 0 007.337 9.059zm34.452-44.617a7.499 7.499 0 001.066-11.903c-9.579-8.718-15.072-21.14-15.072-34.081 0-25.403 20.667-46.07 46.07-46.07s46.07 20.667 46.07 46.07c0 12.941-5.494 25.363-15.072 34.081a7.501 7.501 0 001.066 11.903c11.154 6.989 19.735 17.49 24.42 29.618H76.923c4.685-12.128 13.266-22.631 24.42-29.618z"></path>
      <path d="M63.83 259.688H202.986V274.688H63.83z"></path>
    </svg>

      </StepLabel>
    </Step> */}
    <Step key={2} onClick={() => setStep(1)}>
      <StepLabel>

      <svg className='icon-bar'
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 255.1 255.1"
      viewBox="0 0 255.1 255.1"
    >
      <path
        d="M243.1 207.5c-.2-1-.6-1.9-.8-2.6l-9-30.2c-1.3-4.4-5.3-7.4-10-7.5-4.6-.1-8.8 2.7-10.4 7.1-1.1 3-.5 5.8.3 8.4.5 1.7 1 3.4 1.6 5.1-14.2-8.1-26.2-13.8-37.8-19.4-11.9-5.7-24.2-11.6-38.9-20V61.3c1.9 1.8 3.8 3.7 5.7 5.6 2.9 2.8 6.4 3.7 10.3 2.7 3.8-1 6.7-3.9 7.7-7.7 1-3.7-.2-7.6-3-10.3-7.9-7.7-16-15.5-23.9-23.1l-.3-.3c-4.1-4-10.7-4-14.9.1-.7.7-1.4 1.4-2.1 2-.7.7-1.4 1.4-2.1 2-6.8 6.5-13.7 13.1-19.7 19-3 2.9-4.1 6.3-3.3 10.1.9 4 3.6 7 7.3 8.2 3.7 1.2 7.7.2 10.7-2.6 1.3-1.2 2.6-2.5 3.9-3.7.6-.6 1.3-1.2 1.9-1.8V149.4s-.1.1-.4.3c-15 8.6-27.4 14.6-39.4 20.4-11.4 5.5-23.2 11.2-37.2 19.1.1-.5.3-1 .4-1.5.5-2 1-4.1 1.6-6 .6-2.1.6-4.2 0-6.3-1.5-4.6-5.4-7.6-10.3-7.7-5 0-9.1 3.2-10.5 8.2-2.9 10.7-6 21.8-8.9 32.6-.8 3-.4 6 1.1 8.5s3.9 4.2 6.8 4.9c.4.1.9.2 1.3.3.4.1.9.2 1.3.3 10 2.5 20.4 5 30.7 7.4 4.3 1.1 9.1-.9 11.6-4.8 2.4-3.9 2-9-1-12.7-2.1-2.5-4.8-3.3-7.4-4l-5.1-1.2c14.1-8 25.9-13.7 37.5-19.3C100.5 182 113 176 128 167.4h.1c15.1 8.7 27.6 14.7 39.7 20.5 11.3 5.4 23 11 36.8 18.8-.7.2-1.3.4-2 .5-1.8.5-3.7 1-5.5 1.5-2.2.5-4 1.5-5.4 3.1-3.3 3.6-3.9 8.6-1.5 12.8 1.9 3.4 5.4 5.3 9.2 5.3 1 0 2-.1 3-.4 5.5-1.5 11-2.9 16.5-4.4 5.4-1.4 10.9-2.9 16.3-4.3 3-.7 5.5-2.5 7-5.1 1.4-2.4 1.8-5.4.9-8.2z"
        className="ng-tns-c70-0"
      ></path>
    </svg>

      </StepLabel>
    </Step>
    <Step key={3} onClick={() => setStep(2)}>
      <StepLabel>

      <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      className='icon-bar'
      enableBackground="new 0 0 255.1 255.1"
      viewBox="0 0 255.1 255.1"
    >
      <path
        d="M188.6 127.2c0-.2-.1-.4-.1-.5 0-.2 0-.3-.1-.4-.1-.5-.2-.8-.3-.9 0-.1-.1-.3-.2-.5v-.1c0-.1-.1-.2-.1-.3 0-.1-.1-.2-.1-.2l-.2-.4c-.2-.3-.4-.6-.5-.7-.1-.1-.1-.2-.2-.3-.1-.2-.2-.3-.4-.4l-.1-.1-.1-.2-44.1-44.1c-3.7-3.7-9.8-3.7-13.6 0-1.8 1.8-2.8 4.2-2.8 6.8 0 2.6 1 5 2.8 6.8l27.4 27.4H73.8c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6h82l-27.4 27.4c-1.8 1.8-2.8 4.2-2.8 6.8 0 2.6 1 5 2.8 6.8 1.8 1.8 4.2 2.8 6.8 2.8s5-1 6.8-2.8l43.9-44 .5-.5c.1-.1.2-.3.4-.4.1-.1.2-.2.2-.3.1-.1.1-.2.2-.3.2-.2.4-.5.5-.8.1-.1.1-.2.1-.3 0-.1.1-.1.1-.2.1-.1.1-.2.2-.3.1-.2.1-.3.2-.4l.2-.4v-.2c0-.1 0-.2.1-.3 0-.1.1-.2.1-.4l.2-.2V128c-.2-.3-.2-.6-.3-.8z"
        className="ng-tns-c70-0"
      ></path>
      <path
        d="M126.5 239c-60.8 0-110.3-49.5-110.3-110.3S65.6 18.4 126.5 18.4c60.8 0 110.3 49.5 110.3 110.3S187.3 239 126.5 239zm0-202.6c-50.9 0-92.3 41.4-92.3 92.3S75.6 221 126.5 221s92.3-41.4 92.3-92.3-41.4-92.3-92.3-92.3z"
        className="ng-tns-c70-0"
      ></path>
    </svg>

      </StepLabel>
    </Step>
</Stepper>
</div>

      <h1>{FormTitles[step]}</h1>

     {DynamicalPageSet()}
      
      <div className='container-row'>

      <button className={step === 2 ? 'noDisplay' :'step-btn'} onClick={() => setStep(curr => curr + 1)}><span>{t('Przejdź dalej')}</span> 
      <svg className='arrow-' viewBox="0 0 24 24">
      <path
        d="M22.6 11L15 3.4c-.5-.5-1.4-.5-1.9 0s-.5 1.4 0 1.9l5.3 5.3H2.3c-.7.1-1.3.7-1.3 1.4s.6 1.3 1.3 1.3h16.1l-5.3 5.3c-.5.5-.5 1.4 0 1.9s1.4.5 1.9 0l7.6-7.6c.3-.3.4-.6.4-1 0-.3-.1-.6-.4-.9z"
        className="st0"
      ></path>
      </svg>
      </button>
      </div>
<ToastContainer/>
    </div>
    
    </>

  )
}

export default WidgetMain