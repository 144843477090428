import React, { useState, useEffect, useRef } from 'react'
import { GoogleMap, useJsApiLoader, Marker, Autocomplete, DirectionsRenderer  } from '@react-google-maps/api'
import Geocode from "react-geocode";
import { useTranslation } from 'react-i18next';

const Map = ({formData, setFormData}) => {

    const containerStyle = {
        width: '100%',
        height: '400px'
      };

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyBJcyDmPocbm0PcLpxSMlJ8Pxga4jwaZh0',
        libraries: ['places'],
      })
      Geocode.setApiKey("AIzaSyDSYg61trySrSWegdoHD0gr9JqLm0R_VbM");
      Geocode.setLanguage("pl");
      Geocode.setRegion("pl");
      Geocode.enableDebug();

      useEffect(() => {
        fetch('https://ipinfo.io/myip?token=59576530d67f36')
        .then(response => response.json())
        .then(data => {
          console.log(data)
          const post_code = data.postal
          const city = data.city
          const country = data.country
          setCurrentLocationName(`${post_code} ${city}, ${country}`);
          setFormData({...formData, location: city})
        })
      }, [])

      useEffect(() => {
        const select = document.querySelector('#lang-select');
        if(select !== undefined && select !== null) select.style.display = 'block';
      }, [])
    
      const [map, setMap] = useState((null))
      const [directionsResponse, setDirectionsResponse] = useState(null)
      const [distance, setDistance] = useState('')
      const [duration, setDuration] = useState('')
      const [userCordinate, setUserCordinate] = useState('')
      const [PriceForKilometers,setPriceForKilometers] = useState(0)
      const [AdditionalInformation, setAdditionalInformation] = useState('')
      const [userLocation, setUserLocation] = useState()
      const [currentLocationName, setCurrentLocationName] = useState()
      
      const {t} = useTranslation();
      const originRef = useRef()

      if (!isLoaded) {
        return <h1 style={{ margin: '30px' }}>{t('Proszę czekać')}</h1>
      }

      

    return (
        <>
          <div class="mapouter">
            <label><span>{t('Podaj lokalizację inwestycji')}</span>
              <Autocomplete>
                <input type="text" placeholder={t('Gdzie zamierzasz postawić hale?')} defaultValue={currentLocationName}  onChange={(e) => setFormData({...formData, location: e.target.value})} ref={originRef}/>
              </Autocomplete>
            </label>
          </div>
        </>
    )
}
//setFormData({...formData, location: e.target.value})
export default Map