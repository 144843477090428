import React, { useState, useEffect, useRef } from 'react'
import { GoogleMap, useJsApiLoader, Marker, Autocomplete, DirectionsRenderer  } from '@react-google-maps/api'
import Geocode from "react-geocode";
import { useTranslation } from 'react-i18next';

const Success = () => {
    const {t} = useTranslation();

    useEffect(() => {
        const header = document.querySelector('#header')
        header.style.display = 'none'
    },[])
      
    return (
        <>  
            <div className='widget-form' style={{ justifyContent: 'center', alignSelf: 'center' }}><h3>{t('Dziękujemy za kontakt!')}</h3></div> 
        </>
    )
}
export default Success