import React, { useState, useEffect } from 'react'
import gable from './img/dachform-satteldachpl.png'
import gable_eng from './img/dachform-satteldachen.png'
import gable_de from './img/dachform-satteldachde.png'
import gable_cs from './img/dachform-satteldachcs.png'
import gable_fr from './img/dachform-satteldachfr.png'
import gable_fi from './img/dachform-satteldachfi.png'
import gable_es from './img/dachform-satteldaches.png'
import gable_sk from './img/dachform-satteldachsk.png'
import single_pitched from './img/jednospadowypl.png'
import single_pitched_eng from './img/jednospadowyen.png'
import single_pitched_de from './img/jednospadowyde.png'
import single_pitched_cs from './img/jednospadowycs.png'
import single_pitched_fr from './img/jednospadowyfr.png'
import single_pitched_fi from './img/jednospadowyfi.png'
import single_pitched_es from './img/jednospadowyes.png'
import single_pitched_sk from './img/jednospadowysk.png'
import { useTranslation } from 'react-i18next';

const Configurate = ({ formData, setFormData }) => {
    const {t, i18n} = useTranslation();
    const [housesImage, setHousesImage] = useState('2% 20%')
    const [isVisible, setIsVisible] = useState('none')
    const [isVisible1, setIsVisible1] = useState('none')
    const [image, setImage] = useState();

    useEffect(() => {
        if(i18n.language === 'pl'){
            setImage(gable)
        }else if(i18n.language === 'en'){
            setImage(gable_eng)
        }else if(i18n.language === 'de'){
            setImage(gable_de)
        }else if(i18n.language === 'cs'){
            setImage(gable_cs)
        }else if(i18n.language === 'fr'){
            setImage(gable_fr)
        }else if(i18n.language === 'fi'){
            setImage(gable_fi)
        }else if(i18n.language === 'es'){
            setImage(gable_es)
        }else if(i18n.language === 'be'){
            setImage(gable_fr)
        }else if(i18n.language === 'uks'){
            setImage(gable_eng)
        }else if(i18n.language === 'aut'){
            setImage(gable_de)
        }else if(i18n.language === 'sk'){
            setImage(gable_sk)
        }
    }, [])  
    
    const button = document.querySelector('.step-btn')

    const HandleHouseSet = () => {
            if(formData.type_house == 'Blok mieszkalny') {
                setHousesImage('2% 20%')
                //setFormData({...formData, price: 23600 })
            }  
              if (formData.type_house == 'Chatka') {
                setHousesImage('49% 20%') 
                //setFormData({...formData, price: 63600})
            } 
             if (formData.type_house == 'Rud') {
                setHousesImage('98% 19%')
                //setFormData({...formData, price: 93600})
            }  
              if (formData.type_house === 'Bud') {
                setHousesImage('2% 90%')
                //setFormData({...formData, price: 193600})
            }  
              if (formData.type_house == 'Kanadyjka') {
                setHousesImage('99% 90%')
                //setFormData({...formData, price: 73600})
            }  
    }

   useEffect(() => {
    ExtraInputs()
    HandleHouseSet()
   })

   const ExtraInputs = () => {
        if(formData.type_house === 'Chatka') {
            setIsVisible('flex')
            setIsVisible1('none')
        } else {
            setIsVisible('none')
        }

        if(formData.type_house === 'Kanadyjka') {
            setIsVisible('none')
            setIsVisible1('flex')
        }
        else {
            setIsVisible1('none')
        }
    }
    const [height, setHeight] = useState()

    const Condition = (e) => {
        console.log(e.target.value)
        if(e.target.value === "Dwuspadowy"){
            setImage(gable)
        }else if(e.target.value === "Jednospadowy"){
            setImage(single_pitched)
        }else if(e.target.value === 'Double-decker'){
            setImage(gable_eng)
        }else if(e.target.value === 'Single-decker'){
            setImage(single_pitched_eng)
        }else if(e.target.value === 'Giebel'){
            setImage(gable_de)
        }else if(e.target.value === 'Einspitzig'){
            setImage(single_pitched_de)
        }else if(e.target.value === 'Štít'){
            setImage(gable_cs)
        }else if(e.target.value === 'Jednosvahový'){
            setImage(single_pitched_cs)
        }else if(e.target.value === 'Pignon'){
            setImage(gable_fr)
        }else if(e.target.value === 'Mono-pitch'){
            setImage(single_pitched_fr)
        }else if(e.target.value === 'Gabled'){
            setImage(gable_fi)
        }else if(e.target.value === 'Yksipuolinen'){
            setImage(single_pitched_fi)
        }else if(e.target.value === 'A dos aguas'){
            setImage(gable_es)
        }else if(e.target.value === 'Una cara'){
            setImage(single_pitched_es)
        }else if(e.target.value === 'Double-decker'){
            setImage(gable_sk)
        }else if(e.target.value === 'Single-decker'){
            setImage(single_pitched_sk)
        }
    }
   
    function setValue(e) {
        setFormData({...formData, roof: e.target.value})

        Condition(e)
    }

    if(button !== undefined && button !== null)button.addEventListener('click', (e) => {
        const number = document.querySelector('#number')
        if(number !== undefined && number !== null) setFormData({...formData, hall_area: number.value})
    })

    useEffect(() => {
        console.warn(formData.hall_area)
        const select = document.querySelector('#lang-select');
        if(select !== undefined && select !== null) select.style.display = 'none';
    }, [])

    return (
        <>
            <img src={image} className='image'/>
            <div className='additional-information'>
                <label><span>{t('Kształt dachu')}</span>
                <select onChange={(e) => setValue(e)}>
                    <option>{t('Dwuspadowy')}</option>
                    <option>{t('Jednospadowy')}</option>
                </select></label>
                <label><span>{t('Szerokość(m)')}</span><input type='number' value={formData.width} onInput={(e)=>setFormData({...formData, width: e.target.value})} /></label>
                <label><span>{t('Długość(m)')}</span><input type='number' value={formData.length} onInput={(e)=>setFormData({...formData, length: e.target.value})}/></label>
                <label><span>{t('Wysokość okapu(m)')}</span><input type='number' value={formData.eaves_construction} onChange={(e) =>  {setFormData({...formData, eaves_construction: e.target.value})} } /></label>
                <label><span>{t('Wysokość kalenicy(m)')}</span><input type='number' value={formData.height} onInput={(e) =>{setFormData({...formData, height: e.target.value })}}/></label>
                <label><span>{t('Nachylenie dachu (opcjonalnie)')}</span><input type='number' value={formData.roof_slope} onChange={(e) =>  {setFormData({...formData, roof_slope: e.target.value})}} /></label>
                <label htmlFor="number"><span>{t('Powierzchnia hali(m²)')}</span><input id='number' disabled type='number' value={Math.round((formData.width * formData.length))} onChange={(e) =>  {setFormData({...formData, hall_area: Math.round((formData.width * formData.length))})}} /></label>
            </div>
        </>
    )
}

export default Configurate