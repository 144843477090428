const data = {
    location: "",
    username:  "",
    secondname: '',
    post_code: '',
    civil: 'Kawaler/wdowa',
    function: '',
    name: '',
    roof: 'Dwuspadowy',
      price: 43000,
      ridge_height: '',
      firm: '',
      width: '',
      length: '',
      height: '',
      language: 'Polski',
      email: '',
      set: 'Nie',
      start: '',
      country: '',
      hallenkran: 'Tak',
      phone: '',
      hall_area: '',
      roof_slope: '',
      localization: '',
      address: '',
      sex: 'Mężczyzna',
    permission: 'Nie',
    land_available: 'Nie',
    eaves_construction: '',
    file: '',
    christmas_lights: '',
    additional_subwofer: '',
    coupon: '',
    your_mail: '',
    message: '',
}
export default  {data};