import Languageoption from './components/language-dropdown';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import i18next from 'i18next';
import logo from './images/base_logo_transparent_background.png';
import WidgetMain from './components/WidgetMain';
import { ToastContainer, toast } from 'react-toastify';

const Form = () => {
    const {t} = useTranslation();

    useEffect(() => {
        const path = window.location.pathname.slice(1, window.location.pathname.length);
        i18next.changeLanguage(path);
    }, [])

    const handleClick = (e) => {
      i18next.changeLanguage(e.target.value);
    }
  
    return (
        <>
            <header id='header'>
            <div className="header-inner-left ng-tns-c70-0">
            <div className="header-logo ng-tns-c70-0">
            <img src={logo} className="logo ng-tns-c70-0"/>
            </div>
            
            </div>

            <div id='select-language'><Languageoption onChange={(e) => handleClick(e)}/></div>
            <div className='header-inner-right'>
            <button className='medium-round-btn' onClick={() => window.location.href = '/'}><span>{t('Resetuj')}</span><svg className="ng-tns-c70-0 arrow-sec" viewBox="0 0 24 24">
            <path
                d="M22.6 11L15 3.4c-.5-.5-1.4-.5-1.9 0s-.5 1.4 0 1.9l5.3 5.3H2.3c-.7.1-1.3.7-1.3 1.4s.6 1.3 1.3 1.3h16.1l-5.3 5.3c-.5.5-.5 1.4 0 1.9s1.4.5 1.9 0l7.6-7.6c.3-.3.4-.6.4-1 0-.3-.1-.6-.4-.9z"
                className="st0 ng-tns-c70-0"
            ></path>
            </svg></button>
            </div>

            </header>
            <div className='wrapper'>

            <div id="headerMenu" class="menubar ng-tns-c70-0">

            
            </div>

            <WidgetMain/>

            <ToastContainer/>

            </div>
        </>)

}

export default Form;