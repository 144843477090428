import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { MDBIcon } from 'mdb-react-ui-kit';

const Languageoption = (props) => {
    const {t} = useTranslation();
    const path = window.location.pathname.slice(1, window.location.pathname.length);

//{t('Angielski')}
    return (
        <>
        <select defaultValue={path} id='lang-select' onChange={props.onChange}>
            <option disabled>{t('Wybierz język')}</option>
            <option value={'pl'}>{t('Polska')}</option>
            <option value={'en'}>{t('Anglia')}</option>
            <option value={'aut'}>{t('Austria')}</option>
            <option value={'de'}>{t('Niemcy')}</option>
            <option value={'cs'}>{t('Czechy')}</option>
            <option value={'fr'}>{t('Francja')}</option>
            <option value={'fi'}>{t('Finlandia')}</option>
            <option value={'es'}>{t('Hiszpania')}</option>
            <option value={'be'}>{t('Belgia')}</option>
            <option value={'uks'}>{t('Szkocja')}</option>
            <option value={'sk'}>{t('Słowacja (angielski)')}</option>
        </select>
        </>
    )
};

export default Languageoption;